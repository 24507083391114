import { React, useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useHttp } from '../hooks/useHttp';
import { List, Box, ListItem, Typography, Divider, IconButton } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import { AddForm } from './AddForm';

export const RedactorPage = () => {
	const childRef = useRef();
	const [allProducts, setAllProducts] = useState([]);
	const { request } = useHttp();
	//TODO: сделать взаимодействие на react query
	const getProduct = useCallback(async () => {
		try {
			const fetched =  await request(`/api/`, 'GET', null);
			setAllProducts(fetched);
		} catch(e) {}
	}, [request]);

	useEffect(() => {
		getProduct();
	}, [getProduct]);

	const handleEdit = (product) => {
		childRef.current.handleEdit(product)
	}

	return (
		<>
			<List sx={{ width: '100%', maxWidth: 414, bgcolor: 'background.paper' }}>
				{
					allProducts.map((product) => (
						<Box key={product.id}>
							<ListItem sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
								<img style={{ width: '50px' }} src={product.img} alt={product.name}/>
								<Typography sx={{ p: 0, width: '100%', marginLeft: '5px' }}>{product.name}</Typography>
								<IconButton onClick={() => handleEdit(product)} >
									<ModeIcon/>
								</IconButton>
							</ListItem>
							<Divider/>
						</Box>
					))
				}
			</List>
			<AddForm buttonDisabled={true} edit={true} ref={childRef}/>
		</>
	)
};